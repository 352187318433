@import url('https://fonts.googleapis.com/css?family=Open+Sans&display=swap');

.Dancing-font{  font-family: "Dancing Script", cursive;
    font-optical-sizing: auto;
    font-weight: bolder;
    font-style: normal;
}
    .carousel-item img{ width:auto; height: 90vh;}
    
   
      .footer{
        display: flex;
        justify-content: center;
        align-items: center;
      }
      